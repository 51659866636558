body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.user-select-none {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.text-form-item label {
  color: rgba(0, 0, 0, 0.45) !important;
}

@media print {
  .print-display-none {
    display: none;
  }
  .print-page h5 {
    font-size: 14px;
    line-height: 14px;
  }
  .print-page h4 {
    font-size: 12px !important;;
    line-height: 12px !important;;
  }
  .print-table .ant-table .ant-table-cell,
  .print-table .ant-table .ant-table-footer {
    padding: 2px 8px !important;
    color: #000000 !important;
    font-size: 11px !important;
  }
  .print-table .ant-descriptions * {
    font-size: 11px !important;
  }
  .print-table .ant-table .ant-table-column-sorter-full {
    display: none !important;
  }
  .print-table .ant-table-container::before,
  .print-table .ant-table-container::after {
    box-shadow: none !important;
  }
  .print-page * {
    color: #000000 !important;
  }
  .print-page .print-row .ant-typography,
  .print-page .print-row TD {
    font-size: 11px !important;
    line-height: 11px !important;
  }
  .print-page .print-row TD .ant-typography-secondary {
    font-size: 9px !important;
    padding-top: 5px;
  }
}

:root {
  --gray-point-15: rgba(0, 0, 0, 0.01);
  --gray-point-25: rgba(0, 0, 0, 0.25);
  --gray-point-35: rgba(0, 0, 0, 0.35);
  --gray-point-45: rgba(0, 0, 0, 0.45);
  --light-gray: #efefef;
  --table-th-gray: #fafafa;
  --link-gray: #b1b1b1;
  --link-blue: #1677ff;
  --buy-red: #cf1322;
  --buy-red-border: #cf132270;
  --buy-red-bg: #cf132210;
  --sell-green: #389e0d;
  --sell-gree-border: #389e0d70;
  --sell-green-bg: #389e0d10;
  --rate-color: #0050b3;
}
